"use strict";

yaru.renderEmoji = function () {
    twemoji.parse(document.body, {
        base:       '/assets/2/',
        ext:        '.svg',
        folder:     'svg',
        className:  'yr-emoji'
    });
};

// currently only for emoji so here
yaru.repaint = function () {
    yaru.renderEmoji();
};

$(() => {
    yaru.repaint();
});

$(document).ajaxComplete(() => {
    yaru.repaint();
});
