"use strict";

yaru.ckeditor = (function () {
    let baseUrl = DIR_WEB_ROOT;
    let securityKey = LIVESTREET_SECURITY_KEY;

    let patternSrc = `src=['"]${DIR_WEB_ROOT}/assets/2/svg/[a-z0-9_-]+\\.svg['"]`;
    let patternAlt = `alt=["']([^'">]*)["']`;

    let regexEmojiRemoveRightAlt    = new RegExp(`<img[^>]*${patternSrc}[^>]*${patternAlt}[^>]*>`, 'g');
    let regexEmojiRemoveLeftAlt     = new RegExp(`<img[^>]*${patternAlt}[^>]*${patternSrc}[^>]*>`, 'g'); // mirror attribute order
    let regexEmojiRemoveNoAlt       = new RegExp(`<img[^>]*${patternSrc}[^>]*>`, 'g'); // no alt, just remove

    let emoticons = {
        'smile_smile.png':          ':-)',
        'smile_wink.png':           ';-)',
        'smile_sad.png':            ':-(',
        'smile_sad_wink.png':       ';-(',
        'smile_beer.png':           ':beer:',

        'smile_angry.png':          ']:->',
        'smile_cool.png':           'B-)',
        'smile_surprised.png':      '%-)',
        'smile_tongue.png':         ':-P',
        'smile_bayan_x1.png':       ':[||]:',

        'smile_laugh.png':          ':-D',
        'smile_very_surprised.png': '=-O',
        'smile_neutral.png':        ':-|',
        'smile_headcrab.png':       ':headcrab:',
        'smile_bayan_x2.png':       ':[||||]:',

        'smile_love.png':           ':love:',
        'smile_green.png':          ':-S',
        'smile_plus1.png':          ':+1:',
        'smile_minus1.png':         ':-1:',
        'smile_bayan_x3.png':       ':[||||||||]:',
    };

    let emoticonUrls = emoticons => {
        let urls = [];

        for (let emoticon of emoticons) {
            urls.push(`${ yaru.assets.url(`emoticons/${emoticon}`) }`);
        }

        return urls;
    };

    let commonConfig = {
        language:           'ru',
        customConfig:       false,
        stylesSet:          false,
        allowedContent:     true, // filtering is done by backend
        extraPlugins:       'image,uploadimage,uploadfile,smiley,emoji',
        removeDialogTabs:   'link:target;link:advanced;image:advanced',
        contentsCss: [
            yaru.assets.url('ckeditor.css'),
        ],

        uploadUrl:                  '/ckeditor/upload',
        filebrowserImageUploadUrl:  `/ckeditor/upload?security_ls_key=${securityKey}`,

        smiley_path:            `${baseUrl}`,
        smiley_images:          emoticonUrls(Object.keys(emoticons)),
        smiley_descriptions:    Object.values(emoticons),
        smiley_columns:         5,
    };

    let postConfig = {
        toolbar: [
            {
                name: 'basicstyles',
                items: [ 'Bold', 'Italic', 'Underline', 'Strike', '-', 'RemoveFormat' ],
            },
            {
                name: 'lists',
                items: [ 'NumberedList', 'BulletedList' ],
            },
            {
                name: 'links',
                items: [ 'Link', 'Unlink' ],
            },
            {
                name: 'insert',
                items: [ 'Image', 'Blockquote' ],
            },
            {
                name: 'history',
                items: [ 'Undo', 'Redo' ],
            },
            {
                name: 'emoji',
                items: [ 'EmojiPanel', 'Smiley' ],
            },
            {
                name: 'tools',
                items: [ 'Source', 'Maximize' ],
            },
        ],
    };

    let commentConfig = {
        toolbar: [
            {
                name: 'basicstyles',
                items: [ 'Bold', 'Italic', 'Underline', 'Strike', '-', 'RemoveFormat' ],
            },
            {
                name: 'links',
                items: [ 'Link', 'Unlink' ],
            },
            {
                name: 'insert',
                items: [ 'Image', 'Blockquote' ],
            },
            {
                name: 'emoji',
                items: [ 'EmojiPanel', 'Smiley' ],
            },
            {
                name: 'tools',
                items: [ 'Source', 'Maximize' ],
            },
        ],
    };

    Object.assign(postConfig, commonConfig);
    Object.assign(commentConfig, commonConfig);

    let applyEvents = editor => {
        editor.on('fileUploadRequest', event => {
            event.data.requestData.security_ls_key = securityKey;
        });

        editor.on('key', event => {
            let e = event.data.domEvent.$;

            if (e.ctrlKey && (e.keyCode === 13)) {
                $('#comment-button-submit').click();
                event.cancel();
            }
        });

        editor.on('paste', event => {
            let eventData = event.data;

            if (eventData.type !== 'html') {
                return;
            }

            let data = eventData.dataValue;

            data = data.replace(regexEmojiRemoveRightAlt,   '$1');
            data = data.replace(regexEmojiRemoveLeftAlt,    '$1');
            data = data.replace(regexEmojiRemoveNoAlt,      '');    // no replacement, just remove

            eventData.dataValue = data;
        });
    };

    return {
        addPostEditor(id) {
            let editor = CKEDITOR.replace(id, postConfig);
            applyEvents(editor);
        },

        addCommentEditor(id) {
            let editor = CKEDITOR.replace(id, commentConfig);
            applyEvents(editor);
        },

        removeEditor(id) {
            let editor = CKEDITOR.instances[id];
            if (editor) {
                editor.destroy(true);
            }
        },

        getContent(id) {
            let editor = CKEDITOR.instances[id];
            if (editor) {
                return editor.getData();
            } else {
                return null;
            }
        },

        insertContent(id, html) {
            let editor = CKEDITOR.instances[id];
            if (editor) {
                editor.insertHtml(html);
            }
        },
    }
})();
