/* -------------------------------------------------------
 *
 *   LiveStreet (v1.x)
 *   Plugin Edit comment (v.0.4)
 *   Copyright © 2011 Bishovec Nikolay
 *
 * --------------------------------------------------------
 *
 *   Plugin Page: http://netlanc.net
 *   Contact e-mail: netlanc@yandex.ru
 *
 ---------------------------------------------------------
 */

"use strict";

(function ($) {
    let comments = ls.comments;

    comments.options.text = null;
    comments.options.commentId = 0;
    comments.options.newForm = $('#edit-comment').children('div');

    comments.shbutton = function (formObj, targetId, t) {
        $('.comment-info li.save, .comment-info li.cancel').css('display', 'none');
        $('.comment-info li.edit').css('display', 'block');
        if (t == 'edit') {
            let ulButon = $(formObj).parent('li').parent('ul');
            ulButon.children('li.edit').css('display', 'block');
            ulButon.children('li.save').css('display', 'block');
            ulButon.children('li.cancel').css('display', 'block');
            $(formObj).parent('li').css('display', 'none');
        }
    };

    comments.showform = function (formObj, targetId) {
        comments.shbutton(formObj, targetId, 'edit');
        if (comments.options.commentId == targetId) {
            return false;
        }
        if (this.options.wysiwyg) {
            yaru.ckeditor.removeEditor('form_comment_edit_text');
        }
        let $target = $(`#comment_content_id_${targetId}`);
        let text = $.trim($target.children('div.text').html());
        if (comments.options.commentId != targetId && comments.options.commentId != 0) {
            let $comment = $(`#comment_content_id_${comments.options.commentId}`);

            $target.empty();
            $comment.children('div').appendTo($target);
            $comment.html(comments.options.text);
        } else {
            $target.empty();
            $('#edit-comment').children('div').appendTo($target);
        }
        comments.options.text = text;
        comments.options.commentId = targetId;
        $('#form_comment_edit_text').val(text);
        if (this.options.wysiwyg) {
            yaru.ckeditor.addCommentEditor('form_comment_edit_text');
        }
        return false;
    };

    comments.cancel = function (formObj, targetId) {
        comments.shbutton(formObj, targetId, 'cancel');
        $(`#comment_content_id_${comments.options.commentId}`).children('div').appendTo($('#edit-comment'));
        $(`#comment_content_id_${targetId}`).html(`<div class="text">${comments.options.text}</div>`);
        comments.options.commentId = 0;
        return false;
    };

    comments.edit = function (formObj, targetId) {
        let $text = $('#form_comment_edit_text');
        if (this.options.wysiwyg) {
            $text.val(yaru.ckeditor.getContent($text.attr('id')));
        }
        let text = $text.val();
        ls.ajax(aRouter['ec_ajax'] + 'savecomment/', {
            commentId: targetId,
            text: text,
            security_ls_key: LIVESTREET_SECURITY_KEY
        }, function (result) {
            if (result.bStateError) {
                ls.msg.error(null, result.sMsg);
            } else {
                ls.msg.notice(null, result.sMsg);
                comments.shbutton(formObj, targetId, 'cancel');
                $(`#comment_content_id_${comments.options.commentId}`).children('div').appendTo($('#edit-comment'));
                $(`#comment_content_id_${targetId}`).html(result.sText);
                $(`#info_edit_${targetId}`).html(result.sTextEdit).show();
                comments.options.commentId = 0;
                return false;
            }
        });
    };
})($);
