String.prototype.tr = function (replacements, prefix = null) {
    let string = this;

    for (let key of Object.keys(replacements)) {
        let replacement = replacements[key];

        let placeholderKeys = prefix ? prefix.split('/') : [];
        placeholderKeys.push(key);

        let placeholder = placeholderKeys.join('/');

        if (typeof(replacement) == 'object') {
            string = string.tr(replacement, placeholder);
        } else {
            string = string.replace((new RegExp(`%%${placeholder}%%`, 'g')), replacement);
        }
    }

    return string;
};

/**
 * Управление всплывающими сообщениями
 */
ls.msg = (function () {
    let levels = ['info', 'success', 'warning', 'danger'];

    for (let level of levels) {
        (function (self, level) {
            self[level] = function (title, message = '') {
                if (title) {
                    message = `<strong>${title}</strong><br/>${message}`;
                }

                UIkit.notification({
                    message: message,
                    status: level,
                    pos: 'top-right'
                });
            }
        })(this, level);
    }

    this.notice = this.success; // notice is often used here as success
    this.error = this.danger;

    return this;
}).call(ls.msg || {});

/**
 * Доступ к языковым текстовкам (предварительно должны быть прогружены в шаблон)
 */
ls.lang = (function ($) {
    /**
     * Набор текстовок
     */
    this.msgs = {};

    /**
     * Загрузка текстовок
     */
    this.load = function (msgs) {
        $.extend(true, this.msgs, msgs);
    };

    /**
     * Отображение сообщения об ошибке
     */
    this.get = function (name, replace) {
        if (this.msgs[name]) {
            let value = this.msgs[name];
            if (replace) {
                value = value.tr(replace);
            }
            return value;
        }
        return '';
    };

    return this;
}).call(ls.lang || {}, jQuery);

/**
 * Методы таймера например, запуск функии через интервал
 */
ls.timer = (function () {

    this.aTimers = {};

    /**
     * Запуск метода через определенный период, поддерживает пролонгацию
     */
    this.run = function (fMethod, sUniqKey, aParams, iTime) {
        iTime = iTime || 1500;
        aParams = aParams || [];
        sUniqKey = sUniqKey || Math.random();

        if (this.aTimers[sUniqKey]) {
            clearTimeout(this.aTimers[sUniqKey]);
            this.aTimers[sUniqKey] = null;
        }
        this.aTimers[sUniqKey] = setTimeout(function () {
            clearTimeout(this.aTimers[sUniqKey]);
            this.aTimers[sUniqKey] = null;
            fMethod.apply(this, aParams);
        }.bind(this), iTime);
    };

    return this;
}).call(ls.timer || {});

/**
 * Функционал хранения js данных
 */
ls.registry = (function () {

    this.aData = {};

    /**
     * Сохранение
     */
    this.set = function (sName, data) {
        this.aData[sName] = data;
    };

    /**
     * Получение
     */
    this.get = function (sName) {
        return this.aData[sName];
    };

    return this;
}).call(ls.registry || {});

/**
 * Flash загрузчик
 */
ls.swfupload = (function ($) {

    this.swfu = null;
    this.swfOptions = {};

    this.initOptions = function () {

        this.swfOptions = {
            // Backend Settings
            upload_url: aRouter['photoset'] + "upload",
            post_params: {'SSID': SESSION_ID, 'security_ls_key': LIVESTREET_SECURITY_KEY},

            // File Upload Settings
            file_types: "*.jpg;*.jpe;*.jpeg;*.png;*.gif;*.JPG;*.JPE;*.JPEG;*.PNG;*.GIF",
            file_types_description: "Images",
            file_upload_limit: "0",

            // Event Handler Settings
            file_queue_error_handler: this.handlerFileQueueError,
            file_dialog_complete_handler: this.handlerFileDialogComplete,
            upload_progress_handler: this.handlerUploadProgress,
            upload_error_handler: this.handlerUploadError,
            upload_success_handler: this.handlerUploadSuccess,
            upload_complete_handler: this.handlerUploadComplete,

            // Button Settings
            button_placeholder_id: "start-upload",
            button_width: 122,
            button_height: 30,
            button_text: `<span class="button">${ls.lang.get('topic_photoset_upload_choose')}</span>`,
            button_text_style: '.button { color: #1F8AB7; font-size: 14px; }',
            button_window_mode: SWFUpload.WINDOW_MODE.TRANSPARENT,
            button_text_left_padding: 6,
            button_text_top_padding: 3,
            button_cursor: SWFUpload.CURSOR.HAND,

            // Flash Settings
            flash_url: DIR_ROOT_ENGINE_LIB + '/external/swfupload/swfupload.swf',

            custom_settings: {},

            // Debug Settings
            debug: false
        };

        ls.hook.run('ls_swfupload_init_options_after', arguments, this.swfOptions);

    };

    this.loadSwf = function () {
        let f = {};

        f.onSwfobject = function () {
            if (window.swfobject && swfobject.swfupload) {
                f.onSwfobjectSwfupload();
            } else {
                ls.debug('window.swfobject && swfobject.swfupload is undefined, load swfobject/plugin/swfupload.js');
                $.getScript(
                    DIR_ROOT_ENGINE_LIB + '/external/swfobject/plugin/swfupload.js',
                    f.onSwfobjectSwfupload
                );
            }
        }.bind(this);

        f.onSwfobjectSwfupload = function () {
            if (window.SWFUpload) {
                f.onSwfupload();
            } else {
                ls.debug('window.SWFUpload is undefined, load swfupload/swfupload.js');
                $.getScript(
                    DIR_ROOT_ENGINE_LIB + '/external/swfupload/swfupload.js',
                    f.onSwfupload
                );
            }
        }.bind(this);

        f.onSwfupload = function () {
            this.initOptions();
            $(this).trigger('load');
        }.bind(this);


        (function () {
            if (window.swfobject) {
                f.onSwfobject();
            } else {
                ls.debug('window.swfobject is undefined, load swfobject/swfobject.js');
                $.getScript(
                    DIR_ROOT_ENGINE_LIB + '/external/swfobject/swfobject.js',
                    f.onSwfobject
                );
            }
        }.bind(this))();
    };


    this.init = function (opt) {
        if (opt) {
            $.extend(true, this.swfOptions, opt);
        }
        this.swfu = new SWFUpload(this.swfOptions);
        return this.swfu;
    };

    this.handlerFileQueueError = function (file, errorCode, message) {
        $(this).trigger('eFileQueueError', [file, errorCode, message]);
    };

    this.handlerFileDialogComplete = function (numFilesSelected, numFilesQueued) {
        $(this).trigger('eFileDialogComplete', [numFilesSelected, numFilesQueued]);
        if (numFilesQueued > 0) {
            this.startUpload();
        }
    };

    this.handlerUploadProgress = function (file, bytesLoaded) {
        let percent = Math.ceil((bytesLoaded / file.size) * 100);
        $(this).trigger('eUploadProgress', [file, bytesLoaded, percent]);
    };

    this.handlerUploadError = function (file, errorCode, message) {
        $(this).trigger('eUploadError', [file, errorCode, message]);
    };

    this.handlerUploadSuccess = function (file, serverData) {
        $(this).trigger('eUploadSuccess', [file, serverData]);
    };

    this.handlerUploadComplete = function (file) {
        let next = this.getStats().files_queued;
        if (next > 0) {
            this.startUpload();
        }
        $(this).trigger('eUploadComplete', [file, next]);
    };

    return this;
}).call(ls.swfupload || {}, jQuery);


/**
 * Вспомогательные функции
 */
ls.tools = (function ($) {

    /**
     * Переводит первый символ в верхний регистр
     */
    this.ucfirst = function (str) {
        let f = str.charAt(0).toUpperCase();
        return f + str.substr(1, str.length - 1);
    };

    /**
     * Выделяет все chekbox с определенным css классом
     */
    this.checkAll = function (cssclass, checkbox, invert) {
        $(`.${cssclass}`).each(function (index, item) {
            if (invert) {
                $(item).attr('checked', !$(item).attr("checked"));
            } else {
                $(item).attr('checked', $(checkbox).attr("checked"));
            }
        });
    };

    /**
     * Предпросмотр
     */
    this.textPreview = function (textId, save, divPreview) {
        let text = yaru.ckeditor.getContent(textId);
        let ajaxUrl = aRouter['ajax'] + 'preview/text/';
        let ajaxOptions = { text: text, save: save };
        ls.hook.marker('textPreviewAjaxBefore');
        ls.ajax(ajaxUrl, ajaxOptions, function (result) {
            if (!result) {
                ls.msg.error('Error', 'Please try again later');
            }
            if (result.bStateError) {
                ls.msg.error(result.sMsgTitle || 'Error', result.sMsg || 'Please try again later');
            } else {
                if (!divPreview) {
                    divPreview = 'text_preview';
                }
                let elementPreview = $(`#${divPreview}`);
                ls.hook.marker('textPreviewDisplayBefore');
                if (elementPreview.length) {
                    elementPreview.html(result.sText);
                    ls.hook.marker('textPreviewDisplayAfter');
                }
            }
        });
    };

    /**
     * Возвращает выделенный текст на странице
     */
    this.getSelectedText = function () {
        let text = '';
        if (window.getSelection) {
            text = window.getSelection().toString();
        } else if (window.document.selection) {
            let sel = window.document.selection.createRange();
            text = sel.text || sel;
            if (text.toString) {
                text = text.toString();
            } else {
                text = '';
            }
        }
        return text;
    };


    return this;
}).call(ls.tools || {}, jQuery);


/**
 * Дополнительные функции
 */
ls = (function ($) {

    /**
     * Глобальные опции
     */
    this.options = this.options || {};

    /**
     * Выполнение AJAX запроса, автоматически передает security key
     */
    this.ajax = function (url, params = {}, callback = null, more = {}) {
        params.security_ls_key = LIVESTREET_SECURITY_KEY;

        $.each(params, function (k, v) {
            if (typeof(v) == "boolean") {
                params[k] = v ? 1 : 0;
            }
        });

        if (url.indexOf('http://') != 0 && url.indexOf('https://') != 0 && url.indexOf('/') != 0) {
            url = aRouter['ajax'] + url + '/';
        }

        let ajaxOptions = {
            type: more.type || "POST",
            url: url,
            data: params,
            dataType: more.dataType || 'json',
            success: callback || function () {
                ls.debug("ajax success: ");
                ls.debug.apply(this, arguments);
            }.bind(this),
            error: more.error || function (msg) {
                ls.debug("ajax error: ");
                ls.debug.apply(this, arguments);
            }.bind(this),
            complete: more.complete || function (msg) {
                ls.debug("ajax complete: ");
                ls.debug.apply(this, arguments);
            }.bind(this)
        };

        ls.hook.run('ls_ajax_before', [ajaxOptions], this);

        return $.ajax(ajaxOptions);
    };

    /**
     * Выполнение AJAX отправки формы, включая загрузку файлов
     */
    this.ajaxSubmit = function (url, form, callback, more) {
        more = more || {};
        if (typeof(form) == 'string') {
            form = $(`#${form}`);
        }
        if (url.indexOf('http://') != 0 && url.indexOf('https://') != 0 && url.indexOf('/') != 0) {
            url = aRouter['ajax'] + url + '/';
        }

        let options = {
            type: 'POST',
            url: url,
            dataType: more.dataType || 'json',
            data: { security_ls_key: LIVESTREET_SECURITY_KEY },
            success: callback || function () {
                ls.debug("ajax success: ");
                ls.debug.apply(this, arguments);
            }.bind(this),
            error: more.error || function () {
                ls.debug("ajax error: ");
                ls.debug.apply(this, arguments);
            }.bind(this)

        };

        ls.hook.run('ls_ajaxsubmit_before', [options], this);

        form.ajaxSubmit(options);
    };

    /**
     * Загрузка изображения
     */
    this.ajaxUploadImg = function (form) {
        ls.hook.marker('ajaxUploadImgBefore');
        ls.ajaxSubmit('upload/image/', form, function (data) {
            if (data.bStateError) {
                ls.msg.error(data.sMsgTitle, data.sMsg);
            } else {
                $.markItUp({replaceWith: data.sText});
                let $windowUploadImg = $('#window_upload_img');
                $windowUploadImg.find('input[type="text"], input[type="file"]').val('');
                $windowUploadImg.jqmHide();
                ls.hook.marker('ajaxUploadImgAfter');
            }
        });
    };

    /**
     * Дебаг сообщений
     */
    this.debug = function () {
        if (this.options.debug) {
            this.log.apply(this, arguments);
        }
    };

    /**
     * Лог сообщений
     */
    this.log = function () {
        if (!$.browser.msie && window.console && window.console.log) {
            Function.prototype.bind.call(console.log, console).apply(console, arguments);
        } else {
            //alert(msg);
        }
    };

    return this;
}).call(ls || {}, jQuery);


/**
 * Автокомплитер
 */
ls.autocomplete = (function ($) {
    /**
     * Добавляет автокомплитер к полю ввода
     */
    this.add = function (obj, sPath, multiple) {
        if (multiple) {
            obj.bind("keydown", function (event) {
                if (event.keyCode === $.ui.keyCode.TAB && $(this).data("autocomplete").menu.active) {
                    event.preventDefault();
                }
            })
                .autocomplete({
                    source(request, response) {
                        ls.ajax(sPath, {value: ls.autocomplete.extractLast(request.term)}, function (data) {
                            response(data.aItems);
                        });
                    },
                    search() {
                        let term = ls.autocomplete.extractLast(this.value);
                        if (term.length < 2) {
                            return false;
                        }
                    },
                    focus() {
                        return false;
                    },
                    select(event, ui) {
                        let terms = ls.autocomplete.split(this.value);
                        terms.pop();
                        terms.push(ui.item.value);
                        terms.push("");
                        this.value = terms.join(", ");
                        return false;
                    }
                });
        } else {
            obj.autocomplete({
                source(request, response) {
                    ls.ajax(sPath, {value: ls.autocomplete.extractLast(request.term)}, function (data) {
                        response(data.aItems);
                    });
                }
            });
        }
    };

    this.split = function (val) {
        return val.split(/,\s*/);
    };

    this.extractLast = function (term) {
        return ls.autocomplete.split(term).pop();
    };

    return this;
}).call(ls.autocomplete || {}, jQuery);

(ls.options || {}).debug = 0;
