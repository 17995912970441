"use strict";

function LoadMoreActions(LastActionId) {
    let params = {};
    params['LastActionId'] = LastActionId;

    $("#LoadMoreButton").toggleClass('loading');

    return ls.ajax(aRouter['feedbacks'] + 'LoadMoreActions', params, function (data) {
        if (data.aResult.Errors.length > 0) {
            let $aErrors = data.aResult.Errors;
            for (let $sError of $aErrors) {
                ls.msg.error('', $sError);
            }
        } else {
            $("#stream-list").append(data.aResult.Text);
            $("#LoadMoreButton").remove();
        }
    });
}
