yaru.theme = {
    set(name) {
        let bodyClass;
        let styleFile;

        switch (name) {
            case 'light':
                bodyClass = 'uk-dark';
                styleFile = yaru.assets.url('app.css');
                break;

            case 'dark':
                bodyClass = 'uk-light';
                styleFile = yaru.assets.url('app-dark.css');
                break;

            default:
                ls.msg.error('Некорректная тема');
                return;
        }

        document.body.classList.remove('uk-light', 'uk-dark');
        document.body.classList.add(bodyClass);
        document.getElementById('stylesheet-main').href = styleFile;

        let setTheme = new XMLHttpRequest();
        setTheme.open('POST', `/tools/set-theme?name=${name}`);
        setTheme.send();
    }
};
