$(function ($) {
    let toolbarPos = function () {
        // some logic in grid.css
        let $toolbar = $('#toolbar');
        let $wrapper = $('#wrapper');
        if ($toolbar.find('section').length) {
            if ($(document).width() <= 1100) {
                $toolbar.css({
                    'position': 'absolute',
                    'left': $wrapper.offset().left - 50,
                    'top': $(document).scrollTop() + 136,
                    'display': 'block'
                });
            } else {
                $toolbar.css({
                    'position': 'fixed',
                    'left': $wrapper.offset().left - 50,
                    'top': 136,
                    'display': 'block'
                });
            }
        }
    };

    // Хук начала инициализации javascript-составляющих шаблона
    ls.hook.run('ls_template_init_start', [], window);

    $('html').removeClass('no-js');

    let $body = $('body');

    // Определение браузера
    $body.addClass('webkit');

    // Всплывающие окна
    $('#window_login_form').jqm();
    $('#blog_delete_form').jqm({ trigger: '#blog_delete_show' });
    $('#add_friend_form').jqm({ trigger: '#add_friend_show' });
    $('#favourite-form-tags').jqm();
    $('#modal_write').jqm({ trigger: '.js-write-window-show' });
    $('#foto-resize').jqm({ modal: true });
    $('#userfield_form').jqm({ toTop: true });
    $('#photoset-upload-form').jqm({ trigger: '#photoset-start-upload' });

    // Datepicker
    $('.date-picker').datepicker({
        dateFormat: 'dd.mm.yy',
        dayNamesMin: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
        monthNames: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
        firstDay: 1
    });


    // Поиск по тегам
    $('.js-tag-search-form').submit(function () {
        let val = $(this).find('.js-tag-search').val();
        if (val) {
            window.location = aRouter['tag'] + encodeURIComponent(val) + '/';
        }
        return false;
    });


    // Автокомплит
    ls.autocomplete.add($(".autocomplete-tags-sep"), aRouter['ajax'] + 'autocompleter/tag/', true);
    ls.autocomplete.add($(".autocomplete-tags"), aRouter['ajax'] + 'autocompleter/tag/', false);
    ls.autocomplete.add($(".autocomplete-users-sep"), aRouter['ajax'] + 'autocompleter/user/', true);
    ls.autocomplete.add($(".autocomplete-users"), aRouter['ajax'] + 'autocompleter/user/', false);


    // Тул-бар топиков
    ls.toolbar.topic.init();
    // Кнопка "UP"
    ls.toolbar.up.init();

    toolbarPos();

    $(window).resize(function () {
        toolbarPos();
    });

    $(window).scroll(function () {
        if ($(document).width() <= 1100) {
            toolbarPos();
        }
    });

    // блоки
    ls.hook.add('ls_blocks_init_navigation_after', function (block, count) {
        if ($('.js-block-' + block + '-nav').find('li').length >= count) {
            $('.js-block-' + block + '-dropdown-items').css({ 'top': $('.js-block-' + block + '-dropdown-trigger').offset().top + 25 });
        }
    });

    // инизиализация блоков
    ls.blocks.init('stream', { group_items: true, group_min: 3 });
    ls.blocks.init('blogs');
    ls.blocks.initSwitch('tags');
    ls.blocks.initSwitch('upload-img');
    ls.blocks.initSwitch('favourite-topic-tags');

    // комментарии
    ls.comments.init();

    // избранное
    ls.hook.add('ls_favourite_toggle_after', function (idTarget, objFavourite, type, params, result) {
        $('#fav_count_' + type + '_' + idTarget).text((result.iCount > 0) ? result.iCount : '');
    });

    // вступление в блог
    ls.hook.add('ls_blog_toggle_join_after', function (idBlog, result) {
        if (!this.data('onlyText')) {
            this.html('<i class="icon-synio-join"></i><span>' + (result.bState ? ls.lang.get('blog_leave') : ls.lang.get('blog_join')) + '</span>');
            if (result.bState) {
                this.addClass('active');
            } else {
                this.removeClass('active');
            }
        } else {
            let $buttonAdditional = $(`#${this.data('buttonAdditional')}`);

            if (this.data('buttonAdditional') && $buttonAdditional.length) {
                $buttonAdditional.html(result.bState ? ls.lang.get('blog_leave') : ls.lang.get('blog_join'));
            }
        }
    });

    // лента активности
    ls.hook.add('ls_stream_append_user_after', function (length, data) {
        if (length == 0) {
            $('#strm_u_' + data.uid).parent().find('a').before('<a href="' + data.user_web_path + '"><img src="' + data.user_avatar_48 + '" alt="avatar" class="avatar" /></a> ');
        }
    });

    // стена
    ls.hook.add('ls_wall_loadreplynew_after', function (iPid, idMore, result) {
        if (result.iCountWall) {
            let $wallReplyContainer = $(`#wall-reply-container-${iPid}`);

            if ($wallReplyContainer.length == 0) {
                $('#wall-item-' + iPid).find('.wall-item').after(`<div class="wall-item-replies"><div id="wall-reply-container-${iPid}" class="wall-item-container"></div></div>`);
                $wallReplyContainer.append(result.sText);
            }
        }
    });
    ls.hook.add('ls_wall_remove_reply_item_fade', function () {
        let rpls = $(this).parent('.wall-item-container').parent();
        $(this).remove();
        if (rpls.children().find('.wall-item-reply').length == 0) {
            rpls.remove();
        }
    });
    ls.hook.add('ls_wall_remove_item_fade', function () {
        $(this).remove();
    });

    // опрос
    ls.hook.add('ls_pool_add_answer_after', function () {
        let removeAnchor = $('<a href="#" class="fas fa-trash yr-remove-icon uk-form-small" />').attr('title', ls.lang.get('delete')).click(function (e) {
            e.preventDefault();
            return this.removeAnswer(e.target);
        }.bind(ls.poll));
        $(this).find('a').remove();
        $(this).append(removeAnchor);
    });

    // регистрация
    ls.hook.add('ls_user_validate_registration_fields_after', function (aFields, sForm, result) {
        $.each(aFields, function (i, aField) {
            if (result.aErrors && result.aErrors[aField.field][0]) {
                sForm.find('.form-item-help-' + aField.field).removeClass('active');
            } else {
                sForm.find('.form-item-help-' + aField.field).addClass('active');
            }
        });
    });

    /****************
     * TALK
     */

    // Добавляем или удаляем друга из списка получателей
    $('#friends').find('input:checkbox').change(function () {
        ls.talk.toggleRecipient($('#' + $(this).attr('id') + '_label').text(), $(this).attr('checked'));
    });

    // Добавляем всех друзей в список получателей
    $('#friend_check_all').click(function () {
        $('#friends').find('input:checkbox').each(function (index, item) {
            ls.talk.toggleRecipient($('#' + $(item).attr('id') + '_label').text(), true);
            $(item).attr('checked', true);
        });
        return false;
    });

    // Удаляем всех друзей из списка получателей
    $('#friend_uncheck_all').click(function () {
        $('#friends').find('input:checkbox').each(function (index, item) {
            ls.talk.toggleRecipient($('#' + $(item).attr('id') + '_label').text(), false);
            $(item).attr('checked', false);
        });
        return false;
    });

    // Удаляем пользователя из черного списка
    $("#black_list_block").delegate("a.delete", "click", function () {
        ls.talk.removeFromBlackList(this);
        return false;
    });

    // Удаляем пользователя из переписки
    $("#speaker_list_block").delegate("a.delete", "click", function () {
        ls.talk.removeFromTalk(this, $('#talk_id').val());
        return false;
    });


    /****************
     * DROPDOWN
     */
    let nav_pills_dropdown = $('.nav-pills-dropdown');

    nav_pills_dropdown.each(function (i) {
        let obj = $(this);
        let menu = obj.clone();

        obj.find('li:not(.active)').remove();
        obj.show();

        let timestamp = new Date().getTime();
        let active = $(this).find('li.active');
        let pos = active.offset();

        menu.removeClass().addClass('dropdown-menu').attr('id', 'dropdown-menu-' + timestamp).hide().appendTo('body').css({
            'left': pos.left - 10,
            'top': pos.top + 24,
            'display': 'none'
        });
        active.addClass('dropdown').attr('id', 'dropdown-trigger-' + timestamp).append('<i class="icon-synio-arrows"></i>');

        active.click(function () {
            menu.slideToggle();
            return false;
        });
    });

    $(window).resize(function () {
        nav_pills_dropdown.each(function () {
            let obj = $(this).find('li');
            let timestamp = obj.attr('id').replace('dropdown-trigger-', '');
            let pos = obj.offset();

            $('#dropdown-menu-' + timestamp).css({ 'left': pos.left + 2 });
        });
    });

    // Hide menu
    $(document).click(function () {
        $('.dropdown-menu').hide();
    });

    $body.on("click", ".dropdown-menu", function (e) {
        e.stopPropagation();
    });


    // Help-tags link
    $('.js-tags-help-link').click(function () {
        let s;
        let target = ls.registry.get('tags-help-target-id');
        if (!target) {
            return false;
        }

        let $target = $(`#${target}`);
        if (!$target.length) {
            return false;
        }

        if ($(this).data('insert')) {
            s = $(this).data('insert');
        } else {
            s = $(this).text();
        }

        $.markItUp({ target: $target, replaceWith: s });
        return false;
    });

    // Фикс бага с z-index у встроенных видео
    $("iframe").each(function () {
        let ifr_source = $(this).attr('src');

        if (ifr_source) {
            let wmode = "wmode=opaque";

            if (ifr_source.indexOf('?') != -1)
                $(this).attr('src', ifr_source + '&' + wmode);
            else
                $(this).attr('src', ifr_source + '?' + wmode);
        }
    });

    // Меню
    (function () {
        let trigger = $('#dropdown-mainmenu-trigger');

        if (!trigger.length) {
            return;
        }

        let menu = $('#dropdown-mainmenu-menu');
        menu.appendTo('body').css('display', 'none');

        trigger.click(function () {
            let pos = trigger.offset();
            menu.css({ 'left': pos.left, 'top': pos.top + trigger.height() + 10, 'min-width': trigger.outerWidth() });
            menu.slideToggle();
            trigger.toggleClass('opened');
            return false;
        });

        // Hide menu
        $(document).click(function () {
            trigger.removeClass('opened');
            menu.slideUp();
        });

        $body.on('click', '#dropdown-mainmenu-trigger, #dropdown-mainmenu-menu', function (e) {
            e.stopPropagation();
        });

        $(window).resize(function () {
            menu.css({ 'left': $('#dropdown-mainmenu-trigger').offset().left });
        });
    })();

    // Меню пользователя в шапке
    (function () {
        // Dropdown
        let dp = $('#dropdown-user');
        if (!dp.length) {
            return;
        }
        let trigger = $('#dropdown-user-trigger');
        let menu = $('#dropdown-user-menu');
        let pos = dp.offset();

        menu.appendTo('body').css({
            'left': pos.left,
            'top': dp.height() - 1,
            'min-width': dp.outerWidth(),
            'display': 'none'
        });

        trigger.click(function () {
            menu.slideToggle();
            dp.toggleClass('opened');
            return false;
        });

        menu.find('a').click(function () {
            dp.removeClass('opened');
            trigger.find('a').text($(this).text());
            menu.slideToggle();
        });

        // Hide menu
        $(document).click(function () {
            dp.removeClass('opened');
            menu.slideUp();
        });

        $body.on('click', '#dropdown-user-trigger, #dropdown-user-menu', function (e) {
            e.stopPropagation();
        });

        $(window).resize(function () {
            menu.css({ 'left': $('#dropdown-user').offset().left });
        });
    })();

    // Инициализация строчки поиска
    (function () {
        let search_show = $('#search-header-show');
        if (!search_show.length) {
            return;
        }
        let search_form = $('#search-header-form');
        let write = $('#modal_write_show');

        search_show.click(function () {
            search_form.toggle().find('.input-text').focus();
            $(this).toggle();
            write.toggle();
            return false;
        });

        $(document).click(function () {
            if (search_form.find('.input-text').val() == '') {
                search_form.hide();
                search_show.show();
                write.show();
            }
        });

        $body.on('click', '#search-header-form', function (e) {
            e.stopPropagation();
        });
    })();


    ls.talk.toggleSearchForm = function () {
        $('.talk-search').toggleClass('opened');
        return false;
    };

    ls.blog.toggleInfo = function () {
        let $blogMini = $('#blog-mini');
        let $blog = $('#blog');

        if ($blogMini.is(':visible')) {
            $blogMini.hide();
            $blog.show();
        } else {
            $blogMini.show();
            $blog.hide();
        }

        return false;
    };

    // Хук конца инициализации javascript-составляющих шаблона
    ls.hook.run('ls_template_init_end', [], window);
});
