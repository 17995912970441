"use strict";

/**
 * Управление пользователями
 */
ls.user = (function ($) {

    this.jcropAvatar = null;
    this.jcropFoto = null;

    /**
     * Добавление в друзья
     */
    this.addFriend = function (obj, idUser, sAction) {
        let sText;
        let url;

        let addFriendForm = $('#add_friend_form');

        if (sAction != 'link' && sAction != 'accept') {
            sText = $('#add_friend_text').val();
            addFriendForm.children().each(function (i, item) {
                $(item).attr('disabled', 'disabled')
            });
        } else {
            sText = '';
        }

        if (sAction == 'accept') {
            url = aRouter.profile + 'ajaxfriendaccept/';
        } else {
            url = aRouter.profile + 'ajaxfriendadd/';
        }

        let params = { idUser: idUser, userText: sText };

        ls.hook.marker('addFriendBefore');
        ls.ajax(url, params, function (result) {
            addFriendForm.children().each(function (i, item) {
                $(item).removeAttr('disabled')
            });
            if (!result) {
                ls.msg.error('Error', 'Please try again later');
            }
            if (result.bStateError) {
                ls.msg.error(null, result.sMsg);
            } else {
                ls.msg.notice(null, result.sMsg);
                $('#add_friend_form').jqmHide();
                $('#add_friend_item').remove();
                $('#profile_actions').prepend($(result.sToggleText));
                ls.hook.run('ls_user_add_friend_after', [idUser, sAction, result], obj);
            }
        });
        return false;
    };

    /**
     * Удаление из друзей
     */
    this.removeFriend = function (obj, idUser, sAction) {
        let url = aRouter.profile + 'ajaxfrienddelete/';
        let params = { idUser: idUser, sAction: sAction };

        ls.hook.marker('removeFriendBefore');
        ls.ajax(url, params, function (result) {
            if (result.bStateError) {
                ls.msg.error(null, result.sMsg);
            } else {
                ls.msg.notice(null, result.sMsg);
                $('#delete_friend_item').remove();
                $('#profile_actions').prepend($(result.sToggleText));
                ls.hook.run('ls_user_remove_friend_after', [idUser, sAction, result], obj);
            }
        });
        return false;
    };

    /**
     * Загрузка временной аватарки
     * @param form
     * @param input
     */
    this.uploadAvatar = function (form, input) {
        if (!form && input) {
            form = $('<form method="post" enctype="multipart/form-data"></form>').css({
                'display': 'none'
            }).appendTo('body');
            let clone = input.clone(true);
            input.hide();
            clone.insertAfter(input);
            input.appendTo(form);
        }

        ls.ajaxSubmit(aRouter['settings'] + 'profile/upload-avatar/', form, function (data) {
            if (data.bStateError) {
                ls.msg.error(data.sMsgTitle, data.sMsg);
            } else {
                this.showResizeAvatar(data.sTmpFile);
            }
        }.bind(this));
    };

    /**
     * Показывает форму для ресайза аватарки
     * @param sImgFile
     */
    this.showResizeAvatar = function (sImgFile) {
        if (this.jcropAvatar) {
            this.jcropAvatar.destroy();
        }
        let originalImage = $('#avatar-resize-original-img');
        originalImage.attr('src', `${sImgFile}?${Math.random()}`);
        UIkit.modal('#avatar-resize').show();
        let $this = this;
        originalImage.Jcrop({
            aspectRatio: 1,
            minSize: [64, 64]
        }, function () {
            $this.jcropAvatar = this;
            this.setOptions({
                setSelect: [0, 0, 1000, 1000]
            });
        });
    };

    /**
     * Выполняет ресайз аватарки
     */
    this.resizeAvatar = function () {
        if (!this.jcropAvatar) {
            return false;
        }
        let jc = this.jcropAvatar;
        let url = aRouter.settings + 'profile/resize-avatar/';
        let params = { size: jc.unscale(jc.getSelection()) };

        ls.hook.marker('resizeAvatarBefore');
        ls.ajax(url, params, function (result) {
            if (result.bStateError) {
                ls.msg.error(null, result.sMsg);
            } else {
                $('#avatar-img')
                    .attr('src', `${result.sFile}?${Math.random()}`)
                    .attr('srcset', '')
                ;
                UIkit.modal('#avatar-resize').hide();
                $('#avatar-remove').show();
                $('#avatar-upload').text(result.sTitleUpload);
                ls.hook.run('ls_user_resize_avatar_after', [params, result]);
            }
        });

        return false;
    };

    /**
     * Удаление аватарки
     */
    this.removeAvatar = function () {
        let url = aRouter.settings + 'profile/remove-avatar/';
        let params = {};

        ls.hook.marker('removeAvatarBefore');
        ls.ajax(url, params, function (result) {
            if (result.bStateError) {
                ls.msg.error(null, result.sMsg);
            } else {
                $('#avatar-img')
                    .attr('src', `${result.sFile}?${Math.random()}`)
                    .attr('srcset', '')
                ;
                $('#avatar-remove').hide();
                $('#avatar-upload').text(result.sTitleUpload);
                ls.hook.run('ls_user_remove_avatar_after', [params, result]);
            }
        });

        return false;
    };

    /**
     * Отмена ресайза аватарки, подчищаем временный данные
     */
    this.cancelAvatar = function () {
        let url = aRouter.settings + 'profile/cancel-avatar/';
        let params = {};

        ls.hook.marker('cancelAvatarBefore');
        ls.ajax(url, params, function (result) {
            if (result.bStateError) {
                ls.msg.error(null, result.sMsg);
            } else {
                UIkit.modal('#avatar-resize').hide();
                ls.hook.run('ls_user_cancel_avatar_after', [params, result]);
            }
        });

        return false;
    };

    /**
     * Загрузка временной фотки
     * @param form
     * @param input
     */
    this.uploadFoto = function (form, input) {
        if (!form && input) {
            form = $('<form method="post" enctype="multipart/form-data"></form>').css({
                'display': 'none'
            }).appendTo('body');
            let clone = input.clone(true);
            input.hide();
            clone.insertAfter(input);
            input.appendTo(form);
        }

        ls.ajaxSubmit(aRouter['settings'] + 'profile/upload-foto/', form, function (data) {
            if (data.bStateError) {
                ls.msg.error(data.sMsgTitle, data.sMsg);
            } else {
                this.showResizeFoto(data.sTmpFile);
            }
        }.bind(this));
    };

    /**
     * Показывает форму для ресайза фотки
     * @param sImgFile
     */
    this.showResizeFoto = function (sImgFile) {
        if (this.jcropFoto) {
            this.jcropFoto.destroy();
        }
        let originalImage = $('#foto-resize-original-img');
        originalImage.attr('src', `${sImgFile}?${Math.random()}`);
        UIkit.modal('#foto-resize').show();
        let $this = this;
        originalImage.Jcrop({
            minSize: [32, 32]
        }, function () {
            $this.jcropFoto = this;
            this.setOptions({
                setSelect: [0, 0, 500, 500]
            });
        });
    };

    /**
     * Выполняет ресайз фотки
     */
    this.resizeFoto = function () {
        if (!this.jcropFoto) {
            return false;
        }
        let jc = this.jcropFoto;
        let url = aRouter.settings + 'profile/resize-foto/';
        let params = { size: jc.unscale(jc.getSelection()) };

        ls.hook.marker('resizeFotoBefore');
        ls.ajax(url, params, function (result) {
            if (result.bStateError) {
                ls.msg.error(null, result.sMsg);
            } else {
                $('#foto-img').attr('src', `${result.sFile}?${Math.random()}`);
                UIkit.modal('#foto-resize').hide();
                $('#foto-remove').show();
                $('#foto-upload').text(result.sTitleUpload);
                ls.hook.run('ls_user_resize_foto_after', [params, result]);
            }
        });

        return false;
    };

    /**
     * Удаление фотки
     */
    this.removeFoto = function () {
        let url = aRouter.settings + 'profile/remove-foto/';
        let params = {};

        ls.hook.marker('removeFotoBefore');
        ls.ajax(url, params, function (result) {
            if (result.bStateError) {
                ls.msg.error(null, result.sMsg);
            } else {
                $('#foto-img').attr('src', result.sFile + '?' + Math.random());
                UIkit.modal('#foto-resize').hide();
                $('#foto-upload').text(result.sTitleUpload);
                ls.hook.run('ls_user_remove_foto_after', [params, result]);
            }
        });

        return false;
    };

    /**
     * Отмена ресайза фотки, подчищаем временный данные
     */
    this.cancelFoto = function () {
        let url = aRouter.settings + 'profile/cancel-foto/';
        let params = {};

        ls.hook.marker('cancelFotoBefore');
        ls.ajax(url, params, function (result) {
            if (result.bStateError) {
                ls.msg.error(null, result.sMsg);
            } else {
                UIkit.modal('#foto-resize').hide();
                ls.hook.run('ls_user_cancel_foto_after', [params, result]);
            }
        });

        return false;
    };

    /**
     * Валидация полей формы при регистрации
     * @param aFields
     * @param sForm
     */
    this.validateRegistrationFields = function (aFields, sForm) {
        let url = aRouter.registration + 'ajax-validate-fields/';
        let params = { fields: aFields };
        if (typeof(sForm) == 'string') {
            sForm = $(`#${sForm}`);
        }

        ls.hook.marker('validateRegistrationFieldsBefore');
        ls.ajax(url, params, result => {
            if (!sForm) {
                sForm = $('body'); // поиск полей по всей странице
            }
            $.each(aFields, (i, aField) => {
                if (result.aErrors && result.aErrors[aField.field][0]) {
                    sForm.find(`.yr-validate-error-field-${aField.field}`).addClass('yr-form-validation-show').text(result.aErrors[aField.field][0]);
                    sForm.find(`.yr-validate-input-${aField.field}`).removeClass('uk-form-success').addClass('uk-form-danger');
                } else {
                    sForm.find(`.yr-validate-error-field-${aField.field}`).removeClass('yr-form-validation-show');
                    sForm.find(`.yr-validate-input-${aField.field}`).removeClass('uk-form-danger').addClass('uk-form-success');
                }
            });
            ls.hook.run('ls_user_validate_registration_fields_after', [aFields, sForm, result]);
        });
    };

    /**
     * Валидация конкретного поля формы
     * @param sField
     * @param sValue
     * @param sForm
     * @param aParams
     */
    this.validateRegistrationField = function (sField, sValue, sForm, aParams) {
        const aFields = [];
        aFields.push({ field: sField, value: sValue, params: aParams || {} });
        this.validateRegistrationFields(aFields, sForm);
    };

    /**
     * Ajax регистрация пользователя с проверкой полей формы
     * @param form
     * @param successCallback
     * @param failureCallback
     */
    this.registration = function (form, successCallback = null, failureCallback = null) {
        let url = aRouter.registration + 'ajax-registration/';

        this.formLoader(form);
        ls.hook.marker('registrationBefore');
        ls.ajaxSubmit(url, form, function (result) {
            this.formLoader(form, true);
            if (result.bStateError) {
                ls.msg.error(null, result.sMsg);
            } else {
                if (typeof(form) == 'string') {
                    form = $(`#${form}`);
                }
                form.find('.yr-form-validation-block').removeClass('yr-form-validation-show');
                form.find('.uk-form-danger').removeClass('uk-form-danger');
                if (result.aErrors) {
                    $.each(result.aErrors, function (sField, aErrors) {
                        if (aErrors[0]) {
                            form.find(`.yr-validate-error-field-${sField}`).addClass('yr-form-validation-show').text(aErrors[0]);
                            form.find(`.yr-validate-input-${sField}`).removeClass('uk-form-success').addClass('uk-form-danger');
                        }
                    });
                    if (failureCallback) {
                        failureCallback();
                    }
                } else {
                    if (result.sMsg) {
                        ls.msg.notice(null, result.sMsg);
                    }
                    if (result.sUrlRedirect) {
                        window.location = result.sUrlRedirect;
                    }
                    if (successCallback) {
                        successCallback();
                    }
                }
                ls.hook.run('ls_user_registration_after', [form, result]);
            }
        }.bind(this));
    };

    /**
     * Ajax авторизация пользователя с проверкой полей формы
     * @param form
     * @param successCallback
     * @param failureCallback
     */
    this.login = function (form, successCallback = null, failureCallback = null) {
        let url = aRouter.login + 'ajax-login/';

        this.formLoader(form);
        ls.hook.marker('loginBefore');
        ls.ajaxSubmit(url, form, function (result) {
            this.formLoader(form, true);
            if (typeof(form) == 'string') {
                form = $(`#${form}`);
            }
            form.find('.yr-form-validation-block').removeClass('yr-form-validation-show');

            if (result.bStateError) {
                form.find('.yr-form-validation-login').addClass('yr-form-validation-show');
                form.find('.yr-form-validation-login-text').text(result.sMsg);

                if (failureCallback) {
                    failureCallback();
                }
            } else {
                if (result.sMsg) {
                    ls.msg.notice(null, result.sMsg);
                }

                if (result.sUrlRedirect) {
                    window.location = result.sUrlRedirect;
                }

                if (successCallback) {
                    successCallback();
                }

                ls.hook.run('ls_user_login_after', [form, result]);
            }
        }.bind(this));
    };

    /**
     * Показывает лоадер в полях формы
     * @param form
     * @param bHide
     */
    this.formLoader = function (form, bHide) {
        if (typeof(form) == 'string') {
            form = $(`#${form}`);
        }
        form.find('input[type="text"], input[type="password"]').each(function (k, v) {
            if (bHide) {
                $(v).removeClass('loader');
            } else {
                $(v).addClass('loader');
            }
        });
    };

    /**
     * Ajax запрос на смену пароля
     * @param form
     * @param successCallback
     * @param failureCallback
     */
    this.reminder = function (form, successCallback = null, failureCallback = null) {
        let url = aRouter.login + 'ajax-reminder/';

        this.formLoader(form);
        ls.hook.marker('reminderBefore');
        ls.ajaxSubmit(url, form, function (result) {
            this.formLoader(form, true);
            if (typeof(form) == 'string') {
                form = $(`#${form}`);
            }
            form.find('input').removeClass('uk-form-danger');
            form.find('.yr-form-validation-block').removeClass('yr-form-validation-show');

            if (result.bStateError) {
                form.find('input[name=mail]').addClass('uk-form-danger');
                form.find('.yr-form-validation-reminder').addClass('yr-form-validation-show').text(result.sMsg);

                if (failureCallback) {
                    failureCallback();
                }
            } else {
                form.find('input').val('');

                if (result.sMsg) {
                    ls.msg.notice(null, result.sMsg);
                }

                if (result.sUrlRedirect) {
                    window.location = result.sUrlRedirect;
                }

                if (successCallback) {
                    successCallback();
                }

                ls.hook.run('ls_user_reminder_after', [form, result]);
            }
        }.bind(this));
    };

    /**
     * Ajax запрос на ссылку активации
     * @param form
     */
    this.reactivation = function (form) {
        let url = aRouter.login + 'ajax-reactivation/';

        ls.hook.marker('reactivationBefore');
        ls.ajaxSubmit(url, form, function (result) {
            if (typeof(form) == 'string') {
                form = $(`#${form}`);
            }
            form.find('.validate-error-show').removeClass('validate-error-show').addClass('validate-error-hide');

            if (result.bStateError) {
                form.find('.validate-error-reactivation').removeClass('validate-error-hide').addClass('validate-error-show').text(result.sMsg);
            } else {
                form.find('input').val('');
                if (result.sMsg) {
                    ls.msg.notice(null, result.sMsg);
                }
                ls.hook.run('ls_user_reactivation_after', [form, result]);
            }
        });
    };

    /**
     * Поиск пользователей
     */
    this.searchUsers = function (form) {
        let url = aRouter['people'] + 'ajax-search/';
        let inputSearch = $('#' + form).find('input');
        inputSearch.addClass('loader');

        ls.hook.marker('searchUsersBefore');
        ls.ajaxSubmit(url, form, function (result) {
            inputSearch.removeClass('loader');
            if (result.bStateError) {
                $('#users-list-search').hide();
                $('#users-list-original').show();
            } else {
                $('#users-list-original').hide();
                $('#users-list-search').html(result.sText).show();
                ls.hook.run('ls_user_search_users_after', [form, result]);
            }
        });
    };

    /**
     * Поиск пользователей по началу логина
     */
    this.searchUsersByPrefix = function (sPrefix, obj) {
        obj = $(obj);
        let url = aRouter['people'] + 'ajax-search/';
        let params = { user_login: sPrefix, isPrefix: 1 };
        $('#search-user-login').addClass('loader');

        ls.hook.marker('searchUsersByPrefixBefore');
        ls.ajax(url, params, function (result) {
            $('#search-user-login').removeClass('loader');
            $('#user-prefix-filter').find('.active').removeClass('active');
            obj.parent().addClass('active');
            if (result.bStateError) {
                $('#users-list-search').hide();
                $('#users-list-original').show();
            } else {
                $('#users-list-original').hide();
                $('#users-list-search').html(result.sText).show();
                ls.hook.run('ls_user_search_users_by_prefix_after', [sPrefix, obj, result]);
            }
        });
        return false;
    };

    /**
     * Подписка
     */
    this.followToggle = function (obj, iUserId) {
        if ($(obj).hasClass('followed')) {
            ls.stream.unsubscribe(iUserId);
            $(obj).toggleClass('followed').text(ls.lang.get('profile_user_follow'));
        } else {
            ls.stream.subscribe(iUserId);
            $(obj).toggleClass('followed').text(ls.lang.get('profile_user_unfollow'));
        }
        return false;
    };

    return this;
}).call(ls.user || {}, jQuery);
